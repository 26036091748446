import axios from "@/axios.js"

export default {
    fetchMaintenanceRequests({commit},page){
        return new Promise((resolve,reject)=>{
            axios.get(`api/dashboard/maintenance?page=${page}`)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    changeStatus({commit},id){
        return new Promise((resolve,reject)=>{

            axios.post(`api/dashboard/maintenance/status/${id}`)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },

}
