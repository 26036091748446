import state from './moduleMaintenanceRequestState'
import mutations from './moduleMaintenanceRequestMutations'
import actions from './moduleMaintenanceRequestActions'
import getters from './moduleMaintenanceRequestGetters'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
