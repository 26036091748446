<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">

     <vs-table ref="table"  pagination :max-items="itemsPerPage" search :data="requests">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
        </div>
      </div>

      <template slot="thead">
        <vs-th>الرقم</vs-th>
        <vs-th>الاسم</vs-th>
        <vs-th sort-key="id">رقم الهاتف</vs-th>
        <vs-th sort-key="name">العنوان</vs-th>
        <vs-th sort-key="brand">الرقم التسلسلي</vs-th>
        <vs-th sort-key="start_date">الوصف</vs-th>
        <vs-th sort-key="end_date">التصنيف</vs-th>
        <vs-th sort-key="end_date">الحالة</vs-th>
        <vs-th sort-key="isActive">تاريخ الطلب</vs-th>
        <vs-th>العمليات</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
          <vs-tr :data="request" v-for="(request, index) in requests" :key="index">

              <!-- in all these place holders replace them with {{contest.ObjectHere}} -->

            <vs-td class="img-container">
              # {{request.id}}
            </vs-td>

            <vs-td>
              <p class="product-name font-medium truncate"> {{request.name}} </p>
            </vs-td>

            <vs-td>
              <p class="product-name font-medium truncate"> {{request.phone_number}} </p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{request.address}}</p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{request.serial_number}}</p>
            </vs-td>

            <vs-td>
              <p class="product-price">{{request.description}}</p>
            </vs-td>
            <vs-td>
            <p class="product-price">{{request.category.name}}</p>
            </vs-td>
            <vs-td>
             <vs-chip :color="request.status == 1 ? 'success' : 'danger'" class="product-order-status">
                {{ request.status == 1 ? 'تم الصيانة' : 'لم يتم الصيانة' }}</vs-chip>
            </vs-td>
            <vs-td>
            <p class="product-price">{{request.created_at}}</p>
            </vs-td>

            <vs-td class="whitespace-no-wrap">
              <feather-icon :icon="request.status == 1 ? 'XIcon' :'CheckIcon'" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" title="تم الصيانة" @click="changeStatus(request.id)" />
            </vs-td>

          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import MaintenanceRequest from "@/store/MaintenanceRequest/moduleMaintenanceRequest.js";

export default {
  data() {
    return {
      isMounted: false,
      selected: [],
      current_Page: 1,
      itemsPerPage: 4,
      lastPage: 0,
      totalItems: 0,
      requests: [],
    }
  },
  computed: {
    currentPage() {
      if(this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },

    queriedItems() {
      return this.$refs.table
      ? this.$refs.table.queriedResults.length
      : this.requests.length
    }
  },
  methods: {
    getData(page){
      this.$store.dispatch("MaintenanceRequest/fetchMaintenanceRequests",page).then((response) => {
      this.$vs.loading.close()
      this.requests = response.data.requests;
    });
    },
    changeStatus(id){
      this.$store.dispatch("MaintenanceRequest/changeStatus",id).then((response) => {
      this.$vs.loading.close()
      this.getData(1)
    });
    },
  },
  created() {
    if (!MaintenanceRequest.isRegistered) {
      this.$store.registerModule("MaintenanceRequest", MaintenanceRequest);
      MaintenanceRequest.isRegistered = true;
    }

  },
  beforeMount()
  {
    this.getData(1);
  }

  ,
  mounted() {
    this.$vs.loading()
    this.isMounted = true;
  }
}
</script>








<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }
    .contest-picture{
      max-width:100px;
      max-height:68px;
    }
    .img-container{
      height: 100px;
      max-width:100px;
      max-height:100px;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
